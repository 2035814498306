import { Router } from '@reach/router'
import React from 'react'
import UsersDetailPage from './detail'

const UsersRouter: React.FC<{}> = () => (
  <Router>
    <UsersDetailPage path="users/:id" />
  </Router>
)

export default UsersRouter