import { RouteComponentProps } from '@reach/router'
import React, { useContext, useEffect, useState } from 'react'
import { PrimaryButton, SecondaryButton } from '../../../components/Button'
import CompanyProfile from '../../../components/CompanyProfile/CompanyProfile'
import Layout from '../../../components/Layout'
import SEO from '../../../components/SEO'
import Spinner from '../../../components/Spinner'
import UserContext from '../../../components/UserContext'
import UserProfile from '../../../components/UserProfile'
import Api from '../../../lib/Api'
import Auth from '../../../lib/Auth'
import Logger from '../../../lib/Logger'
import type { User } from '../../../types/unicornvalley'
import './UsersDetail.scss'

interface Props extends RouteComponentProps {
  // eslint-disable-next-line quotes
  id?: User["id"]
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const UsersDetailPage: React.FC<Props> = (props) => {
  const { id: userId } = props
  console.log(props)
  const [
    user,
    _,
  ] = useState({} as User)
  // const [isLoading, setIsLoading] = useState(true)

  // const { user: me } = useContext(UserContext)
  // const canEdit = (
  //   userId &&
  //   `${userId}` === `${me.id}`
  // )

  // useEffect(() => {
  //   (async () => {
  //     const loggedIn = Auth.isLoggedIn()
  //     if (!loggedIn || me.id) {  // wait for me to be set if logged in
  //       try {
  //         setUser(await Api.getUser(userId, { showRemovedCompanies: canEdit }))
  //       } catch (error) {
  //         Logger.logError(error)
  //         window.location.replace(`/404`)
  //       }
  //       setIsLoading(false)
  //     }
  //   })()
  // }, [userId, me, canEdit])

  return (
    <Layout>
      <SEO
        title={user.name}
        description={user.bio}
        image={user.picture}
        type="profile"
      />
      <div id="users-detail-page">
        <div className="user">
          <UserProfile user={user} size='big' />
          <div className="companies">
            {
              (user.companies && Array.isArray(user.companies) && user.companies.length > 0)
                ? <>
                  <h2>Companies</h2>
                  {
                    user.companies.map(company => (
                      <CompanyProfile company={company} key={company.id} />
                    ))
                  }
                </>
                : <p>Looks like {user.name} doesn't have any companies yet</p>
            }
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default UsersDetailPage

